// colors
[data-dark="false"] {
  --primary: #457B9D;
  --secondary: #A8DADC;
  --text: #1D3557;
  --background: #F1FAEE;
  --background-alt: #ecf2ee;
  --light-gray: #e0e0e0;
  --gray: #808080;
  --overlay: #00000020;
}
[data-dark="true"] {
  --primary: #ecf2ee;
  --secondary: #768AA1;
  --text: #F1FAEE;
  --background: #1D3557;
  --background-alt: #1D3557;
  --light-gray: #404040;
  --gray: #808080;
  --overlay: #ffffff10;
}

:root {
  // font families
  --title: "Zen Kaku Gothic New", sans-serif;
  --heading: "Zen Kaku Gothic New", sans-serif;
  --body: "Zen Kaku Gothic New", sans-serif;
  --code: "Fira Mono", monospace;

  // font sizes
  --medium: 1.2rem;
  --large: 1.4rem;
  --xl: 1.6rem;
  --xxl: 1.8rem;

  // font weights
  --thin: 200;
  --regular: 400;
  --semi-bold: 500;
  --bold: 600;

  // text line spacing
  --spacing: 2;

  // effects
  --rounded: 3px;
  --shadow: 0 0 10px 0 var(--overlay);
}
